import * as React from 'react';

import { SimpleButton } from '../components/SimpleButton';

const Homepage = () => {
  return (
    <div className="h-dvh">
      <div className="flex h-96 flex-col items-center justify-evenly">
        <SimpleButton navigatePath="/caregiver">{"I'm a Caregiver"}</SimpleButton>
        <SimpleButton navigatePath="/agency_home">{"I'm an Agency"}</SimpleButton>
        <SimpleButton navigatePath="/admin">Admin</SimpleButton>
      </div>
    </div>
  );
};

export { Homepage };
