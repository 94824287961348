import * as React from 'react';

import { gql, useQuery } from '@apollo/client';
import { IconSearch } from '@tabler/icons-react';

import { FilterOption, FilterPills } from '../components/FilterPills';

import type { Job } from './JobsList';
import { JobsList } from './JobsList';

const { useState } = React;

const GET_JOBS = gql`
  query GetJobs {
    jobs(status: open) {
      id
      name
      start
      end
      status
      rate
      previousRate
      distance
    }
  }
`;

export const filterOptions: FilterOption[] = [
  { label: 'All Shifts', value: 'all_shifts' },
  { label: 'Near Me', value: 'near_me' },
  { label: 'Fits My Schedule', value: 'fits_my_schedule' },
  { label: 'My Skill Level', value: 'my_skill_level' },
  { label: 'My Min Pay Rate', value: 'my_min_pay_rate' },
  { label: 'Recurring', value: 'recurring' },
] as const;

const shuffleArray = <T,>(array: T[]): T[] => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const CaregiverFind = () => {
  const { loading, error, data: jobsData } = useQuery(GET_JOBS);
  const [filters, setFilters] = useState<string[]>(['near_me']);

  const onFilterClick = (option: FilterOption) => {
    if (option.value === 'all_shifts') {
      setFilters(['all_shifts']);
    } else {
      const newFilters = filters.filter((filter) => filter !== 'all_shifts');
      if (filters.includes(option.value)) {
        setFilters(newFilters.filter((filter) => filter !== option.value));
      } else {
        setFilters([...newFilters, option.value]);
      }
    }
  };

  // We filter out non-open jobs on the backend via the query, but if someone
  // applies for a job, but if the status changes due to an action in the
  // application, the Apollo cache may have more up-to-date data than the
  // backend, which this filter catches.
  const jobs = jobsData?.jobs?.filter((job: Job) => job.status === 'open');

  const randomizedJobs: Job[] = React.useMemo(() => {
    if (jobsData?.jobs) {
      return shuffleArray(jobsData.jobs);
    }
    return [];
  }, [jobsData?.jobs]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="flex flex-col px-2 py-4 md:px-8">
      <IconSearch className="mb-6" />
      <FilterPills filterOptions={filterOptions} filters={filters} onClick={onFilterClick} />
      <JobsList jobs={jobs} name="Recommended for you" />
      <JobsList jobs={randomizedJobs} name="Near you" />
    </div>
  );
};

export { CaregiverFind };
