import * as React from 'react';

import avatar from '../static/avatar.png';

const HelloCareHeroHeader = () => {
  return (
    <div className="m-2 flex items-center gap-4 sm:m-4">
      <img src={avatar} alt="avatar" className="h-16 w-16" />
      <div>
        <span className="text-2xl font-normal text-black">Hello</span>
        <span className="text-2xl font-black text-black"> Care Hero!</span>
      </div>
    </div>
  );
};

export { HelloCareHeroHeader };
