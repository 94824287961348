import * as React from 'react';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { HelloCareHeroHeader } from '../components/HelloCareHeroHeader';
import { CalendarEvent, SimpleCalendar } from '../components/SimpleCalendar';

const RETRIEVE_EVENTS = gql`
  query RetrieveScheduledJobs($caregiver: ID!) {
    jobs(user: $caregiver) {
      id
      name
      start
      end
      status
    }
  }
`;

const jobToEvent = (job): CalendarEvent => {
  return {
    title: job.name,
    start: new Date(job.start),
    end: new Date(job.end),
  };
};

const CaregiverCalendar = () => {
  const { id: caregiverId } = useParams();
  const { data, loading, error } = useQuery(RETRIEVE_EVENTS, {
    variables: { caregiver: caregiverId },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div>
      <HelloCareHeroHeader />
      <SimpleCalendar events={data.jobs.map(jobToEvent)} />
    </div>
  );
};

export { CaregiverCalendar };
