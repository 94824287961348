import * as React from 'react';

type PillVariant = 'primary' | 'secondary' | 'attention';

type PillProps = {
  label: string;
  variant: PillVariant;
  onClick?: () => void;
};
export const Pill: React.FC<PillProps> = ({ label, variant, onClick }) => {
  let bgColor: string;
  let textColor: string;
  switch (variant) {
    case 'primary':
      bgColor = 'bg-blue-600';
      textColor = 'text-white';
      break;
    case 'secondary':
      bgColor = 'bg-indigo-50';
      textColor = 'text-blue-600';
      break;
    case 'attention':
      bgColor = 'bg-[#DCEEDC]';
      textColor = 'text-[#00A507]';
      break;
  }

  return (
    <div
      className={
        'px-2 py-1.5 rounded-xl justify-center items-center gap-1 flex ' +
        bgColor +
        ` ${onClick ? 'cursor-pointer' : ''}`
      }
      onClick={onClick}
    >
      <div className="flex items-start justify-start gap-2.5 px-1">
        <div
          className={`text-nowrap text-center text-xs font-semibold uppercase tracking-wide ${textColor}`}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
