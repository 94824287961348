import React from 'react';

export type Column<T> = {
  header: string;
  // eslint-disable-next-line no-unused-vars
  accessor: keyof T | ((item: T) => React.ReactNode);
};

type AdminTableProps<T> = {
  data: T[];
  columns: Column<T>[];
  // eslint-disable-next-line no-unused-vars
  computeKey: (item: T) => string;
  title?: string;
};

const AdminTable = <T extends Record<string, any>>({
  data,
  columns,
  title,
}: AdminTableProps<T>) => (
  <div className="overflow-hidden rounded bg-white shadow-lg sm:rounded-lg">
    {title && (
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      </div>
    )}
    <div className="border-t border-gray-200">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {typeof column.accessor === 'function'
                    ? column.accessor(item)
                    : item[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export { AdminTable };
