import * as React from 'react';

import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';

import { AdminButton } from '../components/AdminButton';
import { AdminTable, Column } from '../components/AdminTable';

const GET_USERS_AND_CAREGIVERS = gql`
  query GetUsersAndCaregivers {
    users {
      id
      name
      email
      caregiverInfo {
        id
        phone
        workType
      }
    }
    moods {
      user {
        id
        name
      }
      averageMood
      moodCount
    }
    jobs {
      id
      name
      status
      start
      end
      userId
    }
  }
`;

const RESET_DATABASE = gql`
  mutation ResetDatabase {
    resetDatabase {
      success
      msg
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      success
      msg
    }
  }
`;

const DELETE_JOB = gql`
  mutation DeleteJob($id: ID!) {
    deleteJob(id: $id) {
      success
      msg
    }
  }
`;

type User = {
  id: string;
  name: string | null;
  email: string;
  caregiverInfo: {
    id: string;
    phone: string | null;
    workType: string | null;
  } | null;
};

type MoodAggregate = {
  user: {
    id: string;
    name: string;
  };
  averageMood: number;
  moodCount: number;
};

type Job = {
  id: string;
  name: string;
  status: string;
  start: string;
  end: string;
  userId: string | null;
};

const DeleteUserButton = ({ user }: { user: User }) => {
  const [deleteUser] = useMutation(DELETE_USER);
  const client = useApolloClient();
  return (
    <AdminButton
      variant="warning"
      onClick={() => {
        if (window.confirm(`Are you sure you want to delete ${user.name}?`)) {
          deleteUser({ variables: { id: user.id } })
            .then(() => client.refetchQueries({ include: [GET_USERS_AND_CAREGIVERS] }))
            .catch((error) => alert(`Error deleting user: ${error.message}`));
        }
      }}
    >
      Delete
    </AdminButton>
  );
};

const userColumns: Column<User>[] = [
  { header: 'Name', accessor: 'name' },
  { header: 'ID', accessor: 'id' },
  { header: 'Email', accessor: 'email' },
  { header: 'Phone', accessor: (user) => user.caregiverInfo?.phone || 'N/A' },
  { header: 'Work Type', accessor: (user) => user.caregiverInfo?.workType || 'N/A' },
  {
    header: 'View',
    accessor: (user) => (
      <AdminButton to={`/caregiver/${user.id}`}>
        View as {user.name ? user.name.split(' ')[0] : 'user'}
      </AdminButton>
    ),
  },
  {
    header: 'Delete',
    accessor: (user) => <DeleteUserButton user={user} />,
  },
];

const DeleteJobButton = ({ job }: { job: Job }) => {
  const [deleteJob] = useMutation(DELETE_JOB);
  const client = useApolloClient();
  return (
    <AdminButton
      variant="warning"
      onClick={() => {
        if (window.confirm(`Are you sure you want to delete job ${job.name}?`)) {
          deleteJob({ variables: { id: job.id } })
            .then(() => client.refetchQueries({ include: [GET_USERS_AND_CAREGIVERS] }))
            .catch((error) => alert(`Error deleting job: ${error.message}`));
        }
      }}
    >
      Delete
    </AdminButton>
  );
};

const jobColumns: Column<Job>[] = [
  { header: 'Job Name', accessor: 'name' },
  { header: 'Status', accessor: 'status' },
  { header: 'Start', accessor: (job) => new Date(job.start).toLocaleDateString() },
  { header: 'End', accessor: (job) => new Date(job.end).toLocaleDateString() },
  { header: 'Assigned To', accessor: 'userId' },
  {
    header: 'Delete',
    accessor: (job) => <DeleteJobButton job={job} />,
  },
];

const moodColumns: Column<MoodAggregate>[] = [
  { header: 'Name', accessor: (item) => item.user.name },
  { header: 'Average Mood', accessor: 'averageMood' },
  { header: 'Mood Count', accessor: 'moodCount' },
];

const Admin = () => {
  const { loading, error, data, refetch } = useQuery(GET_USERS_AND_CAREGIVERS);
  const [resetDatabase] = useMutation(RESET_DATABASE);

  const resetButton = (
    <AdminButton
      variant="warning"
      onClick={() =>
        resetDatabase()
          .then((result) => alert(`Succcess: ${result.data.resetDatabase.msg}`))
          .then(() => refetch())
          .catch((result) => alert(`Failure: ${result.data.resetDatabase.msg}`))
      }
    >
      Reset database
    </AdminButton>
  );

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <>
        <p>Error :(</p>
        <div className="m-4">{resetButton}</div>
      </>
    );

  return (
    <div className="grid grid-cols-[1fr_auto_1fr] gap-x-4 gap-y-8">
      <div className="col-start-2 flex w-fit items-center gap-4">
        Controls:
        {resetButton}
      </div>
      <div className="col-start-2 w-fit">
        <AdminTable
          data={data.users}
          columns={userColumns}
          computeKey={(user) => user.id}
          title="Users and Caregivers"
        />
      </div>
      <div className="col-start-2 w-fit">
        <AdminTable
          data={data.jobs}
          columns={jobColumns}
          computeKey={(job) => job.id}
          title="Jobs"
        />
      </div>
      <div className="col-start-2 w-fit">
        <AdminTable
          data={data.moods}
          columns={moodColumns}
          computeKey={(mood) => mood.user.id}
          title="Aggregated Mood Information"
        />
      </div>
    </div>
  );
};

export { Admin };
