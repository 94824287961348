import * as React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import { Admin } from './admin/pages/Admin';
import { Page } from './components/page';
import { AgencyHome } from './pages/agency_home';
import { ApplicationSuccess } from './pages/ApplicationSuccess';
import { CaregiverCalendar } from './pages/CaregiverCalendar';
import { CaregiverFind } from './pages/CaregiverFind';
import { CaregiverHome } from './pages/CaregiverHome';
import { CaregiverLanding } from './pages/CaregiverLanding';
import { CaregiverMessages } from './pages/CaregiverMessages';
import { CaregiverProfile } from './pages/CaregiverProfile';
import { CaregiverRoot } from './pages/CaregiverRoot';
import { CaregiverShifts } from './pages/CaregiverShifts';
import { Homepage } from './pages/homepage';
import { HowAreYouFeeling } from './pages/HowAreYouFeeling';
import { JobDetail } from './pages/JobDetail';
import { Root } from './pages/root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/',
        element: (
          <Page title="Home">
            <Homepage />
          </Page>
        ),
      },
      {
        path: '/admin',
        element: (
          <Page title="Caregivers">
            <Admin />
          </Page>
        ),
      },
      {
        path: '/agency_home',
        element: (
          <Page title="Home">
            <AgencyHome />
          </Page>
        ),
      },
      { path: '/caregiver/welcome', element: <HowAreYouFeeling /> },
      { path: '/caregiver', element: <CaregiverLanding /> },
      {
        path: '/caregiver',
        element: <CaregiverRoot />,
        children: [
          {
            path: '/caregiver/:id',
            element: <CaregiverHome />,
          },
          {
            path: '/caregiver/:id/find',
            element: <CaregiverFind />,
          },
          {
            path: '/caregiver/:id/shifts',
            element: <CaregiverShifts />,
          },
          {
            path: '/caregiver/:id/schedule',
            element: <CaregiverCalendar />,
          },
          {
            path: '/caregiver/:id/messages',
            element: <CaregiverMessages />,
          },
          {
            path: '/caregiver/:id/profile',
            element: <CaregiverProfile />,
          },
          {
            path: '/caregiver/:id/job/:jobId',
            element: <JobDetail />,
          },
          {
            path: '/caregiver/:id/job/:jobId/application_success',
            element: <ApplicationSuccess />,
          },
        ],
      },
    ],
  },
]);

export { router };
