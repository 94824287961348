import * as React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { IconChevronLeft, IconStar, IconStarFilled } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../components/Button';
import { Pill } from '../components/Pill';
import { CaregiverContext } from '../context/CaregiverContext';
import { useCaregiverNavigate } from '../hooks/useCaregiverNavigate';

import { GET_MY_JOBS } from './CaregiverShifts';

const GET_JOB = gql`
  query GetJob($id: ID!) {
    job(id: $id) {
      id
      name
      start
      end
      status
      rate
      previousRate
      distance
      location
      recurring
    }
  }
`;

const APPLY_TO_JOB = gql`
  mutation ApplyToJob($job: ID!, $user: ID!) {
    apply(job: $job, user: $user) {
      id
      status
    }
  }
`;

const Stars: React.FC<{ rating: number }> = ({ rating }) => {
  const stars: React.JSX.Element[] = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<IconStarFilled key={i} color="#006FFD" />);
    } else {
      stars.push(<IconStar key={i} color="#D4D6DD" />);
    }
  }
  return <div className="flex gap-1">{stars}</div>;
};

type ApplyDrawerProps = { jobId: string };

const ApplyDrawer: React.FC<ApplyDrawerProps> = ({ jobId }) => {
  const navigate = useCaregiverNavigate();
  const { caregiver } = React.useContext(CaregiverContext);
  const [applyToJob, { client }] = useMutation(APPLY_TO_JOB);
  return (
    <div className="bg-white">
      <div className="m-2 flex justify-center">
        <Button
          text="Apply"
          variant="primary"
          onClick={async () => {
            await applyToJob({ variables: { job: jobId, user: caregiver.id } });
            await client.refetchQueries({ include: [GET_MY_JOBS] });
            navigate(`/job/${jobId}/application_success`);
          }}
        />
      </div>
    </div>
  );
};

const JobDetail = () => {
  const { jobId } = useParams();
  const { loading, error, data } = useQuery(GET_JOB, {
    variables: { id: jobId },
  });
  const navigate = useNavigate();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { job } = data;

  const duration = new Date(job.end).getTime() - new Date(job.start).getTime();
  const durationHours = Math.floor(duration / (1000 * 60 * 60));

  return (
    <div className="flex h-screen flex-col">
      <div className="flex-grow overflow-y-auto">
        <div className="relative flex w-full items-center py-4">
          <div className="absolute left-2 cursor-pointer" onClick={() => navigate(-1)}>
            <IconChevronLeft className="h-8 w-8" color="#2563eb" />
          </div>
          <div className="flex-grow text-center font-['Inter'] text-sm font-bold text-neutral-800">
            {job.name}
          </div>
        </div>

        <div className="mx-4">
          <div className="text-center font-['Inter'] text-lg font-bold text-green-600">
            This shift is a great match for you!
          </div>
          <div className="pt-4 font-['Inter'] text-lg font-black text-neutral-800">{job.name}</div>
          <div className="font-['Inter'] text-xs font-bold text-zinc-500">
            Date: {new Date(job.start).toLocaleDateString()}
            <br />
            Time: {new Date(job.start).toLocaleTimeString()} -{' '}
            {new Date(job.end).toLocaleTimeString()}
          </div>
        </div>
        <div className="flex flex-col gap-5 p-4">
          <div className="font-['Inter'] text-xs font-bold text-neutral-800">Pay</div>
          <div className="flex gap-2">
            {job.previousRate ? (
              <Pill label={`BOOST RATE: $${job.rate}/hour`} variant="attention" />
            ) : (
              <Pill label={`$${job.rate}/hour`} variant="secondary" />
            )}
            <Pill label={`${durationHours} hour shift`} variant="secondary" />
          </div>
          <div className="flex h-14 flex-col items-start justify-start gap-4 self-stretch">
            <div className="w-64 font-['Inter'] text-xs font-bold text-neutral-800">Recurring?</div>
            <div className="flex gap-2">
              {job.recurring ? (
                <>
                  <Pill label="Yes" variant="secondary" />
                  <Pill label="Weekly" variant="secondary" />
                </>
              ) : (
                <Pill label="No" variant="secondary" />
              )}
            </div>
          </div>
          <div className="flex flex-col items-start gap-4">
            <div className="w-64 font-['Inter'] text-xs font-bold text-neutral-800">Location</div>
            <Pill label={job.location} variant="secondary" />
          </div>
          <div className="flex flex-col items-start justify-start gap-4">
            <div className="font-['Inter'] text-xs font-bold text-neutral-800">Client Details</div>
            <div className="mr-2 rounded-xl border border-stone-300 px-3 pt-3">
              <div className="font-['Inter'] text-sm font-normal text-neutral-400">
                Elderly female client looking for companionship, meal prep, bathing and medication
                reminders.
                <br /> <br />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="font-['Inter'] text-xs font-bold text-neutral-800">Agency Rating</div>
            <div className="font-['Inter'] text-xs font-normal leading-none tracking-tight text-zinc-500">
              Your shifts: 2
              <br />
              Friend shifts: 10
              <br />
              Agency Overall Rating:
            </div>
            <Stars rating={4} />
          </div>
          <div className="flex gap-4">
            <Pill label="Fast Response" variant="secondary" />
            <Pill label="Clear Care Notes" variant="secondary" />
          </div>
        </div>
      </div>
      <ApplyDrawer jobId={jobId} />
    </div>
  );
};

export { JobDetail };
