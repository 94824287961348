import * as React from 'react';

import { gql, useQuery } from '@apollo/client';
import { IconChevronRight, IconPhotoFilled } from '@tabler/icons-react';

import { FilterOption, FilterPills } from '../components/FilterPills';
import { HelloCareHeroHeader } from '../components/HelloCareHeroHeader';
import { CaregiverContext } from '../context/CaregiverContext';

const GET_MY_JOBS = gql`
  query GetMyJobs($user: ID!) {
    jobs(user: $user) {
      id
      name
      start
      end
      status
      rate
      distance
    }
  }
`;
const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  hour12: true,
});

const formatDate = (isoString: string): string => dateFormatter.format(new Date(isoString));

const formatTime = (isoString: string): string =>
  timeFormatter.format(new Date(isoString)).replace(' ', '');

const formatDurationInHours = (startIso: string, endIso: string): string => {
  const start = new Date(startIso);
  const end = new Date(endIso);
  const durationMs = end.getTime() - start.getTime();
  const hours = Math.round(durationMs / (1000 * 60 * 60));
  return `${hours}hrs`;
};

const filterOptions: FilterOption[] = [
  { label: 'New Offers', value: 'new_offers' },
  { label: 'Applied Shifts', value: 'applied_shifts' },
  { label: 'My Shifts', value: 'my_shifts' },
];

const CaregiverShifts = () => {
  const [filters, setFilters] = React.useState<string[]>(['applied_shifts']);
  const { caregiver } = React.useContext(CaregiverContext);

  const { loading, error, data } = useQuery(GET_MY_JOBS, {
    variables: { user: caregiver.id },
  });

  const jobsToShow = data?.jobs.filter((job) => {
    if (filters.includes('new_offers')) {
      return job.status === 'open';
    }
    if (filters.includes('applied_shifts')) {
      return job.status === 'pending';
    }
    if (filters.includes('my_shifts')) {
      return job.status === 'confirmed';
    }
    return true;
  });

  return (
    <div>
      <HelloCareHeroHeader />
      <div className="flex justify-center py-4">
        <FilterPills
          filterOptions={filterOptions}
          filters={filters}
          onClick={(option) => setFilters([option.value])}
        />
      </div>
      <div>
        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {jobsToShow && (
          <div className="flex flex-col gap-2">
            {jobsToShow.map((job: any) => (
              <div
                key={job.id}
                className="mx-6 flex h-24 items-center gap-3 rounded-xl bg-slate-100"
              >
                <div className="flex h-24 w-20 items-center justify-center rounded-l-xl bg-[#eaf2ff] text-blue-200">
                  <IconPhotoFilled />
                </div>
                <div>
                  <div className="font-['Inter'] text-sm font-extrabold text-neutral-800">
                    {formatDate(job.start)}
                  </div>
                  <div className="font-['Inter'] text-xs font-normal text-zinc-500">
                    {formatTime(job.start)} - {formatTime(job.end)} (
                    {formatDurationInHours(job.start, job.end)})
                    <br />
                    Menlo Park - {job.distance}
                    <br />
                    {job.name}
                  </div>
                </div>
                <div className="text-xs">{/* todo status icon */ job.status}</div>
                <div className="grow">{/* spacer to keep chevron aligned to far right */}</div>
                <IconChevronRight className="mr-2" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { CaregiverShifts, GET_MY_JOBS };
