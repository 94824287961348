import * as React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { Outlet, useLocation, Link } from 'react-router-dom';

const auth0Config = {
  domain:
    process.env.NODE_ENV === 'development'
      ? 'dev-qzhktaf23ebuzo73.us.auth0.com'
      : 'honestcare.us.auth0.com',
  clientId:
    process.env.NODE_ENV === 'development'
      ? 'OEaysXAzguZrgkMCEB38j4ukylgboZuZ'
      : 'mHCy7EcObw5nCr8zbrKJKSrKR7YsoyaS',
};

const Root = () => {
  const route = useLocation();
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + '/caregiver',
      }}
    >
      <div className="relative flex flex-col bg-white font-sans">
        {!route.pathname.startsWith('/caregiver') && (
          <div id="header" className="flex flex-row px-4 pt-2 font-serif">
            <Link to="/">
              <h1 className="z-0 text-2xl">honest care</h1>
            </Link>
          </div>
        )}
        <div id="main-content">
          <Outlet />
        </div>
      </div>
    </Auth0Provider>
  );
};

export { Root };
