import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { CaregiverContext } from '../context/CaregiverContext';

const { useContext } = React;

const useCaregiverNavigate = () => {
  const navigate = useNavigate();
  const user = useContext(CaregiverContext);
  return (suffix: string) => {
    if (!user?.caregiver) {
      navigate('/caregiver');
      return;
    }
    navigate(`/caregiver/${user.caregiver.id}${suffix}`);
  };
};

export { useCaregiverNavigate };
