import * as React from 'react';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

const { createContext } = React;

const GET_CAREGIVER = gql`
  query GetCaregiver($id: ID!) {
    user(id: $id) {
      id
      name
      email
      username
    }
  }
`;

type User = {
  caregiver: {
    id: string;
    name: string;
    email?: string;
    username?: string;
  };
};

export const CaregiverContext = createContext<undefined | User>(undefined);

interface CaregiverProviderProps {
  children: React.ReactNode;
}

export const CaregiverProvider: React.FC<CaregiverProviderProps> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useQuery(GET_CAREGIVER, {
    variables: { id },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading profile :(</div>;

  return (
    <CaregiverContext.Provider value={{ caregiver: data.user }}>
      {children}
    </CaregiverContext.Provider>
  );
};
