import * as React from 'react';

import { CaregiverContext } from '../context/CaregiverContext';

const CaregiverHome = () => {
  const { caregiver } = React.useContext(CaregiverContext);
  if (!caregiver.name) return <div>Error, your profile doesn&apos;t have a name :(</div>;

  return <div>hello, {caregiver.name}!</div>;
};

export { CaregiverHome };
