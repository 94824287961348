import * as React from 'react';

import { IconPhotoFilled } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';

const JobsList: React.FC<{ jobs: Job[]; name: string }> = ({ jobs, name }) => {
  return (
    <div>
      <div>
        <div className="flex justify-between px-4 py-5 sm:px-6">
          <h3 className="font-bold leading-6 text-gray-900">{name}</h3>
          <p className="mt-1 text-sm text-blue-600">See more</p>
        </div>
        <div className="flex gap-2 overflow-auto sm:gap-8">
          {jobs.map((job) => (
            <JobCard job={job} key={job.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export type Job = {
  id: string;
  name: string;
  start: string;
  end: string;
  status: string;
  rate: number;
  previousRate: number;
  distance: string;
  location: string;
};

const JobCard: React.FC<{ job: Job }> = ({ job }) => {
  const { id: caregiverId } = useParams();
  const startDate = new Date(job.start);
  const day = startDate.toLocaleDateString('en-US', {
    weekday: 'short',
  });

  const startTime = shortTimeFormat(startDate);
  const endTime = shortTimeFormat(new Date(job.end));

  return (
    <div className="h-60 w-64 rounded-2xl bg-slate-50">
      <div className="relative h-28 w-64">
        <div className="inline-flex h-28 w-64 flex-col items-center justify-center rounded-t-2xl bg-indigo-50 p-10">
          <div className="h-8 w-8 text-blue-200">
            <IconPhotoFilled />
          </div>
        </div>
        <div className="absolute right-2 top-2 inline-flex items-center justify-center gap-1 rounded-xl bg-blue-600 px-2 py-1.5">
          <div className="flex items-start justify-start gap-2.5 px-1">
            <div className="text-center text-xs uppercase tracking-wide text-white">{day}</div>
          </div>
          {job.previousRate && <div className="h-3 w-3 rounded-full bg-green-600" />}
        </div>
      </div>
      <div className="flex h-32 flex-col gap-4 text-nowrap p-4">
        <div className="flex h-9 flex-col gap-1">
          <div className="text-sm font-extrabold text-neutral-800">{job.name}</div>
          <div className="flex flex-row justify-between text-xs text-zinc-500">
            <div>{job.distance}</div>
            <div>|</div>
            <div>
              <span>
                {startTime} - {endTime}
              </span>
            </div>
            <div>|</div>
            {job.previousRate ? (
              <div className="flex gap-1">
                <div className="line-through">${job.previousRate}/hr</div>
                <div className="font-bold text-green-700">${job.rate}/hr</div>
              </div>
            ) : (
              <div>${job.rate}/hr</div>
            )}
          </div>
        </div>
        <Link
          to={`/caregiver/${caregiverId}/job/${job.id}`}
          className="inline-flex h-10 items-center justify-center gap-2 self-stretch rounded-xl border border-blue-600 px-4 py-3"
        >
          <div className="text-xs font-semibold text-blue-600">See More</div>
        </Link>
      </div>
    </div>
  );
};

export { JobsList };
const shortTimeFormat = (date: Date): string => {
  let time = date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase()
    .replace(/\s/g, '');

  // Remove minutes if they're '00'
  time = time.replace(/:00/, '');
  return time;
};
