import * as React from 'react';

type ModalPageProps = {
  children: React.ReactNode;
};

const ModalPage: React.FC<ModalPageProps> = ({ children }) => {
  return (
    <div className="flex h-screen w-screen items-center justify-center overflow-hidden bg-neutral-700">
      <div className="w-72 rounded-2xl bg-white p-4">{children}</div>
    </div>
  );
};

export { ModalPage };
