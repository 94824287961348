import * as React from 'react';

import { IconEdit, IconSearch } from '@tabler/icons-react';

const CaregiverMessages = () => {
  return (
    <div className="flex flex-col gap-4">
      <header className="flex items-center justify-between p-4">
        <div className="text-xs text-blue-500">Edit</div>
        <div className="text-sm">Messages</div>
        <IconEdit className="text-blue-500" width={18} />
      </header>
      <div className="mx-8 flex h-8 items-center justify-start gap-4 rounded-3xl bg-slate-100 px-4">
        <IconSearch className="text-neutral-400" width={18} />
        <div className="">
          <div className="text-sm text-neutral-400">Search</div>
        </div>
      </div>
    </div>
  );
};

export { CaregiverMessages };
