import * as React from 'react';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const;
type Day = (typeof days)[number];

export type CalendarEvent = {
  start: Date;
  end: Date;
  title: string;
};
const getCurrentWeekDates = (): number[] => {
  const today = new Date();
  const currentDay = today.getDay();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - (currentDay === 0 ? 6 : currentDay - 1));

  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);
    return date.getDate();
  });
};

const getCurrentDay = (): Day => {
  const dayIndex = new Date().getDay();
  return days[dayIndex === 0 ? 6 : dayIndex - 1];
};

const formatTime = (date: Date): string => {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const additionalStylesFor = (isCurrentDay: boolean, isSelectedDay: boolean): string => {
  if (isSelectedDay) return 'bg-blue-600';
  if (isCurrentDay) return ' bg-[#eaf2ff]';
  return 'bg-white';
};

type DatesHeaderProps = {
  // eslint-disable-next-line no-unused-vars
  setSelectedDay: (day: number) => void;
  selectedDay?: number;
};

const DatesHeader: React.FC<DatesHeaderProps> = ({ setSelectedDay, selectedDay }) => {
  const currentWeekDates = getCurrentWeekDates();
  const currentDay = days.indexOf(getCurrentDay());
  return (
    <div className="sticky top-0 z-30 flex-none bg-white ring-1 ring-black ring-opacity-5 sm:pr-8">
      <div className="mx-6 flex flex-row justify-between pb-2 sm:hidden">
        {days.map((day, i) => {
          const isSelectedDay = i === selectedDay;
          return (
            <button
              type="button"
              className={
                'flex flex-col items-center rounded-2xl p-2.5 ' +
                additionalStylesFor(i === currentDay, isSelectedDay)
              }
              onClick={() => setSelectedDay(i)}
              key={day}
            >
              <span className={'text-xs ' + (isSelectedDay && 'text-blue-200')}>
                {day.substring(0, 2).toUpperCase()}
              </span>
              <span className={isSelectedDay ? 'text-white' : 'text-neutral-600'}>
                {currentWeekDates[i]}
              </span>
            </button>
          );
        })}
      </div>
      <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
        <div className="col-end-1 w-14" />
        {days.map((day, i) => (
          <div className="flex items-center justify-center py-3" key={day}>
            <span>
              {day}{' '}
              <span className="items-center justify-center font-semibold text-gray-900">
                {currentWeekDates[i]}
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const SimpleCalendar = ({ events }: { events: CalendarEvent[] }) => {
  const [selectedDay, setSelectedDay] = React.useState<number>(days.indexOf(getCurrentDay()));

  return (
    <div className="flex flex-col">
      <div className="isolate flex flex-auto flex-col overflow-auto rounded bg-white">
        <div
          style={{ width: '165%' }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <DatesHeader setSelectedDay={setSelectedDay} selectedDay={selectedDay} />
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{
                  gridTemplateRows: 'repeat(17, minmax(2rem, 1fr))',
                }}
              >
                <div className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10PM
                  </div>
                </div>
                <div />
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: '1.75rem repeat(34, minmax(0, 1fr)) auto',
                }}
              >
                {events.map((calendarEvent) => {
                  // TODO: this is mobile only logic
                  if ((calendarEvent.start.getDay() - 1) % 7 != selectedDay) return null;

                  const startCol =
                    calendarEvent.start.getDay() === 0 ? 7 : calendarEvent.start.getDay();

                  const startHour = calendarEvent.start.getHours();
                  const startMinute = calendarEvent.start.getMinutes();
                  // Grid is set up in 15m blocks (4 per hour), starting at ???
                  const startRow = startHour * 4 + Math.floor(startMinute / 15) - 32;

                  const durationMs = calendarEvent.end.getTime() - calendarEvent.start.getTime();
                  const durationMinutes = durationMs / (1000 * 60);
                  const spanRows = Math.ceil(durationMinutes / 30);

                  return (
                    <li
                      className={`sm:col-start-${startCol}` + ' relative mt-px flex'}
                      style={{
                        gridRow: `${startRow} / span ${spanRows}`,
                      }}
                      key={`${calendarEvent.start.toISOString()}-${calendarEvent.title}`}
                    >
                      <a className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100">
                        <p className="order-1 font-semibold text-blue-700">{calendarEvent.title}</p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                          <time dateTime={calendarEvent.start.toISOString()}>
                            {formatTime(calendarEvent.start)} - {formatTime(calendarEvent.end)}
                          </time>
                        </p>
                      </a>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SimpleCalendar };
