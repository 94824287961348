import * as React from 'react';

import { gql, useQuery } from '@apollo/client';

// TODO: fix this query
const GET_CAREGIVERS = gql`
  query GetCaregivers {
    caregivers {
      id
      name
    }
  }
`;

const AgencyHome = () => {
  const { loading, error, data } = useQuery(GET_CAREGIVERS);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Caregivers</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Details and information about caregivers.
        </p>
      </div>
      <div className="border-t border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                ID
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data.caregivers.map((caregiver) => (
              <tr key={caregiver.id}>
                <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                  {caregiver.name}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {caregiver.id}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { AgencyHome };
