import * as React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { IconChevronRight, IconEdit } from '@tabler/icons-react';

import { CaregiverContext } from '../context/CaregiverContext';
import avatar from '../static/avatar.png';

const ProfileRow = ({ text, onClick }: { text: string; onClick: () => void }) => {
  return (
    <div
      className="mx-8 flex h-12 cursor-pointer items-center justify-between border-b"
      onClick={onClick}
    >
      <div className="text-sm text-neutral-700">{text}</div>
      <IconChevronRight className="text-neutral-500" />
    </div>
  );
};

const CaregiverProfile = () => {
  const { logout } = useAuth0();
  const { caregiver } = React.useContext(CaregiverContext);
  return (
    <div className="flex flex-col gap-8">
      <div className="mt-8 flex flex-col items-center gap-2">
        <div className="relative h-16 w-16">
          <img src={avatar} alt="avatar" />
          <div className="absolute bottom-0 right-0">
            <IconEdit className="text-neutral-600" />
          </div>
        </div>
        <div className="font-bold">{caregiver.name}</div>
        <div className="text-xs text-neutral-500">@{caregiver.username ?? 'no-username-set'}</div>
      </div>
      <div className="mx-8 flex flex-col gap-2">
        <div>
          Level - <span className="font-semibold"> Superhero </span>
        </div>
        <div className="relative">
          <div className="h-4 w-full rounded-full bg-[#D4D6DD]"></div>
          <div className="absolute top-0 h-4 w-[40%] rounded-full bg-[#DCEEDC]"></div>
        </div>
        <div className="text-xs">
          10 shifts completed (50 care hours)
          <div className="font-bold">Complete 20 more hours to be a Champion!</div>
        </div>
      </div>
      <div className="flex flex-col">
        <ProfileRow text="Edit Profile" onClick={() => {}} />
        <ProfileRow text="Friends" onClick={() => {}} />
        <ProfileRow text="Past Shifts" onClick={() => {}} />
        <ProfileRow text="My Pay" onClick={() => {}} />
        <ProfileRow text="My Coach" onClick={() => {}} />
        <ProfileRow text="Log out" onClick={logout} />
      </div>
    </div>
  );
};

export { CaregiverProfile };
