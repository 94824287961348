import * as React from 'react';

import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Button } from '../components/Button';
import { ModalPage } from '../components/ModalPage';
import { CaregiverContext } from '../context/CaregiverContext';
import { useCaregiverNavigate } from '../hooks/useCaregiverNavigate';

const CANCEL_APPLICATION = gql`
  mutation CancelApplication($job: ID!, $user: ID!) {
    cancelApplication(job: $job, user: $user) {
      id
    }
  }
`;

const ApplicationSuccess = () => {
  const navigate = useCaregiverNavigate();
  const [cancelApplication] = useMutation(CANCEL_APPLICATION);
  const { caregiver } = React.useContext(CaregiverContext);
  const { jobId } = useParams();

  const cancel = async () => {
    await cancelApplication({ variables: { job: jobId, user: caregiver.id } });
    alert('Application cancelled!');
    navigate('/find');
  };

  return (
    <ModalPage>
      <div className="flex flex-col gap-2 pt-2">
        <div className="text-center font-['Inter'] text-base font-black tracking-tight text-neutral-800">
          Good Luck!
        </div>
        <div className="self-stretch text-center font-['Inter'] text-xs font-normal leading-none tracking-tight text-zinc-500">
          Thank you for applying! <br />
          This shift has been added to “Applied Shifts”. You should hear back soon!
        </div>
        <div className="flex flex-row gap-2 text-nowrap">
          <Button variant="secondary" text="Cancel application" onClick={cancel} />
          <Button variant="primary" text="Back home" onClick={() => navigate('/find')} />
        </div>
      </div>
    </ModalPage>
  );
};

export { ApplicationSuccess };
