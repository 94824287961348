import * as React from 'react';

import { gql, useMutation } from '@apollo/client';
import {
  IconMoodConfuzed,
  IconMoodEmpty,
  IconMoodHappy,
  IconMoodSad,
  IconMoodSmile,
} from '@tabler/icons-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import logo from '../static/logo.png';

const RECORD_MOOD = gql`
  mutation RecordMood($mood: Int!, $caregiver: ID!) {
    recordMood(mood: $mood, user: $caregiver) {
      success
    }
  }
`;

const moodMap = {
  frowning: 1,
  slightly_frowning: 2,
  neutral: 3,
  slightly_smiling: 4,
  smiling: 5,
};

const HowAreYouFeeling = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sendMood] = useMutation(RECORD_MOOD);
  const caregiver = searchParams.get('id');
  const navigateToFind = React.useCallback(() => {
    navigate(`/caregiver/${caregiver}/find`);
  }, [navigate, caregiver]);

  const handleClick = (mood: string) => () => {
    // fire and forget; best effort
    sendMood({ variables: { mood: moodMap[mood], caregiver } });
    navigateToFind();
  };

  return (
    <>
      <div className="absolute left-0 top-0 h-screen w-screen bg-amber-500/20">
        <div className="flex w-full justify-center">
          <img src={logo} />
        </div>
        <div className="w-full text-center text-3xl">
          <h1 className="font-cursive">How are you feeling today?</h1>
        </div>
        <div></div>
        <div className="flex w-full justify-between px-8 pt-5">
          <div>
            <div
              className="h-12 w-12 cursor-pointer rounded-full bg-amber-500 p-1 text-white"
              onClick={handleClick('smiling')}
            >
              <IconMoodHappy className="h-full w-full" />
            </div>
          </div>
          <div>
            <div
              className="h-12 w-12 cursor-pointer rounded-full bg-amber-500 p-1 text-white"
              onClick={handleClick('slightly_smiling')}
            >
              <IconMoodSmile className="h-full w-full" />
            </div>
          </div>
          <div>
            <div
              className="h-12 w-12 cursor-pointer rounded-full bg-amber-500 p-1 text-white"
              onClick={handleClick('neutral')}
            >
              <IconMoodEmpty className="h-full w-full" />
            </div>
          </div>
          <div>
            <div
              className="h-12 w-12 cursor-pointer rounded-full bg-amber-500 p-1 text-white"
              onClick={handleClick('slightly_frowning')}
            >
              <IconMoodConfuzed className="h-full w-full" />
            </div>
          </div>
          <div>
            <div
              className="h-12 w-12 cursor-pointer rounded-full bg-amber-500 p-1 text-white"
              onClick={handleClick('frowning')}
            >
              <IconMoodSad className="h-full w-full" />
            </div>
          </div>
        </div>
        <div className="mt-8 flex justify-center">
          <div
            className="cursor-pointer rounded-xl bg-zinc-500/30 px-10 py-0.5"
            onClick={() => navigateToFind()}
          >
            <div className="font-cursive text-xl">Ask me later</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { HowAreYouFeeling };
