import * as React from 'react';

import { useNavigate } from 'react-router-dom';

type ButtonVariant = 'primary' | 'secondary' | 'attention' | 'muted';

interface ButtonProps {
  variant: ButtonVariant;
  text: string;
  onClick?: () => void;
  to?: string;
}

const Button: React.FC<ButtonProps> = ({ variant, text, onClick, to }) => {
  const navigate = useNavigate();
  const handleClick = onClick ? onClick : to ? () => navigate(to) : undefined;

  if (!handleClick) {
    throw new Error('Button must have either onClick or to prop');
  }

  let styles: string;
  switch (variant) {
    case 'primary':
      styles = 'bg-blue-600 text-white';
      break;
    case 'secondary':
      styles = 'bg-white text-blue-600 border border-blue-600';
      break;
    case 'attention':
      styles = 'bg-green-700 text-white';
      break;
    case 'muted':
      styles = 'bg-gray-100 text-gray-700';
      break;
    default:
      styles = 'bg-blue-600 text-white';
  }

  return (
    <div
      className={`h-12 w-80 px-4 py-3 ${styles} inline-flex items-center justify-center gap-2 rounded-xl`}
      onClick={handleClick}
    >
      <div className="font-['Inter'] text-xs font-semibold">{text}</div>
    </div>
  );
};

export { Button };
