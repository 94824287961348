import { useMatch, useParams } from 'react-router-dom';

const useActiveRoute = () => {
  const params = useParams();

  const routeInfo = {
    isHome: useMatch('/caregiver/:id') !== null,
    isFind: useMatch('/caregiver/:id/find') !== null,
    isShifts: useMatch('/caregiver/:id/shifts') !== null,
    isSchedule: useMatch('/caregiver/:id/schedule') !== null,
    isMessages: useMatch('/caregiver/:id/messages') !== null,
    isProfile: useMatch('/caregiver/:id/profile') !== null,
    isJobDetail: useMatch('/caregiver/:id/job/:jobId') !== null,
    isApplicationSuccess: useMatch('/caregiver/:id/application_success') !== null,
    caregiverId: params.id,
    jobId: params.jobId,
  };
  return {
    ...routeInfo,
    isFullscreen: routeInfo.isJobDetail || routeInfo.isApplicationSuccess,
  };
};

export default useActiveRoute;
