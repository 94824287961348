import * as React from 'react';

import { useNavigate } from 'react-router-dom';

type SimpleButtonProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  navigatePath?: string;
};

const SimpleButton: React.FC<SimpleButtonProps> = ({ children, onClick, navigatePath }) => {
  let handleClick;
  if (!onClick && !navigatePath) {
    throw new Error('Either onClick or navigatePath must be provided');
  } else if (onClick && navigatePath) {
    throw new Error('Only one of onClick or navigatePath can be provided');
  } else if (onClick) {
    handleClick = onClick;
  } else if (navigatePath) {
    handleClick = () => navigate(navigatePath);
  }

  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export { SimpleButton };
