import * as React from 'react';

import { useNavigate } from 'react-router-dom';

type AdminButtonProps = {
  children: React.ReactNode;
  to?: string;
  variant?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  navigatePath?: string;
};

const AdminButton: React.FC<AdminButtonProps> = ({
  children,
  to,
  onClick,
  variant = 'primary',
}) => {
  let buttonClassName =
    'rounded px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2';

  switch (variant) {
    case 'primary':
      buttonClassName += ' bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600';
      break;
    case 'secondary':
      buttonClassName += ' bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600';
      break;
    case 'warning':
      buttonClassName += ' bg-red-600 hover:bg-red-500 focus-visible:outline-red-600';
      break;
    default:
      throw new Error('Invalid button variant');
  }

  let handleClick;
  if (!onClick && !to) {
    throw new Error('Either onClick or to must be provided');
  } else if (onClick && to) {
    throw new Error('Only one of onClick or to can be provided');
  } else if (onClick) {
    handleClick = onClick;
  } else if (to) {
    handleClick = () => navigate(to);
  }

  const navigate = useNavigate();

  return (
    <button type="button" className={buttonClassName} onClick={handleClick}>
      {children}
    </button>
  );
};

export { AdminButton };
