import * as React from 'react';

import { gql, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { SimpleButton } from '../components/SimpleButton';

const { useEffect } = React;

const CREATE_CAREGIVER = gql`
  mutation CreateUser($email: String!, $name: String) {
    user(email: $email, name: $name) {
      id
      email
      justCreated
    }
  }
`;

// For some reason, in the Arc browser, the default openUrl
// (window.location.assign) opens in a Little Arc and messes up
// the UX. Overriding to window.location.replace fixes this.
export const openUrl = (url: string) => {
  window.location.replace(url);
};

const CaregiverLanding = () => {
  const { loginWithRedirect, logout, isAuthenticated, isLoading, user } = useAuth0();

  const navigate = useNavigate();
  const [createCaregiver] = useMutation(CREATE_CAREGIVER);

  useEffect(() => {
    if (user?.email) {
      createCaregiver({ variables: { email: user.email, name: user.name } }).then((res) =>
        navigate(`/caregiver/welcome?id=${res.data.user.id}`),
      );
    }
  }, [createCaregiver, navigate, user]);

  if (isLoading) return <div>loading...</div>;

  if (isAuthenticated) {
    if (!user.email) {
      return (
        <div>
          Something went wrong :( <br /> Your account does not have an email address, for some
          reason
        </div>
      );
    }
    return (
      <div>
        <div>Hello, {user.name}!</div>
        <br />
        <div>Wait a sec while we load your profile...</div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <SimpleButton
            onClick={() => {
              logout({
                openUrl,
                logoutParams: { returnTo: window.location.origin },
              });
            }}
          >
            log out
          </SimpleButton>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>you are not logged in :(</div>
        <div>
          <SimpleButton
            onClick={() => {
              loginWithRedirect({ openUrl });
            }}
          >
            log in
          </SimpleButton>
        </div>
      </div>
    );
  }
};

export { CaregiverLanding };
