import * as React from 'react';

import { Pill } from './Pill';

export type FilterOption = {
  label: string;
  value: string;
};

type FilterPillsProps = {
  filterOptions: FilterOption[];
  // eslint-disable-next-line no-unused-vars
  onClick: (option: FilterOption) => void;
  filters: string[];
};

export const FilterPills: React.FC<FilterPillsProps> = ({ filterOptions, onClick, filters }) => {
  return (
    <div className="overflow-auto">
      <div className="inline-flex items-start justify-start gap-2 pb-2">
        {filterOptions.map((option) => (
          <Pill
            key={option.value}
            label={option.label}
            variant={filters.includes(option.value) ? 'primary' : 'secondary'}
            onClick={() => onClick(option)}
          />
        ))}
      </div>
    </div>
  );
};
