import * as React from 'react';

import {
  IconCompassFilled,
  IconBriefcase2Filled,
  IconMessageFilled,
  IconCalendarFilled,
  IconUserFilled,
} from '@tabler/icons-react';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import { CaregiverProvider } from '../context/CaregiverContext';
import useActiveRoute from '../hooks/useActiveRoute';

const CaregiverRoot = () => {
  const { id } = useParams();
  const tabClassFn = ({ isActive }) => {
    return (
      'w-16 flex flex-col items-center justify-center text-xs ' +
      (isActive
        ? 'custom-font-bold first:*:text-blue-600 last:*:text-black'
        : 'text-gray-400 hover:text-gray-500')
    );
  };
  const { isFullscreen } = useActiveRoute();

  const bottomNav = (
    <footer
      id="bottom-nav"
      className="fixed bottom-0 left-0 flex h-12 w-screen justify-around bg-white "
    >
      <NavLink to={`/caregiver/${id}/find`} className={tabClassFn}>
        <IconCompassFilled />
        <div>Find</div>
      </NavLink>
      <NavLink className={tabClassFn} to={`/caregiver/${id}/shifts`}>
        <IconBriefcase2Filled />
        <div>Shifts</div>
      </NavLink>
      <NavLink className={tabClassFn} to={`/caregiver/${id}/schedule`}>
        <IconCalendarFilled />
        <div>Calendar</div>
      </NavLink>
      <NavLink className={tabClassFn} to={`/caregiver/${id}/messages`}>
        <IconMessageFilled />
        <div>Messages</div>
      </NavLink>
      <NavLink className={tabClassFn} to={`/caregiver/${id}/profile`}>
        <IconUserFilled />
        <div>Profile</div>
      </NavLink>
    </footer>
  );

  return (
    <CaregiverProvider>
      <div className={isFullscreen ? '' : 'mb-12'}>
        <Outlet />
      </div>
      {!isFullscreen && bottomNav}
    </CaregiverProvider>
  );
};

export { CaregiverRoot };
